import * as React from 'react';
import { Link } from 'react-router-dom';
import { setToStorage } from '../helpers/storage';
import { Carousel } from 'react-responsive-carousel';

export interface TutorialScreenProps {
  menuPosition: number;
  setTutorialToComplete: () => void;
}

export interface TutorialScreenState {
  currentSlide: number;
}

export default class TutorialScreen extends React.Component<
  TutorialScreenProps,
  TutorialScreenState
> {
  carouselRef: React.RefObject<any>;
  constructor(props: TutorialScreenProps) {
    super(props);
    this.carouselRef = React.createRef<Carousel>();
  }

  public state: TutorialScreenState = {
    currentSlide: 0,
  };

  componentDidMount() {}

  private nextSlide = () => {
    (this.carouselRef.current as any).increment();
  };

  private getSlideInformation = (slideNumber: number) => {
    (this.carouselRef.current as any).state.selectedItem === 2
      ? (() => {
          console.log('SliderNumber: ', slideNumber);
          this.props.setTutorialToComplete();
          setToStorage('hasViewedTutorial', true);
        })()
      : null;
  };

  render() {
    return (
      <React.Fragment>
        <Carousel
          showThumbs={false}
          showArrows={false}
          useKeyboardArrows={true}
          showStatus={false}
          onChange={(slideNumber: number) =>
            this.getSlideInformation(slideNumber)
          }
          ref={this.carouselRef}
        >
          <div>
            <h1>Hello I'm Grace.</h1>
            <p>Your personal rradar legal assistant</p>
            <button
              className='grace-hollow-button-white'
              onClick={this.nextSlide}
            >
              Continue
            </button>
          </div>
          <div>
            <h1>Interact With Grace.</h1>
            <p>
              Ask questions using your phone's microphone, or send typed
              messages.
            </p>
            <button
              className='grace-hollow-button-white'
              onClick={this.nextSlide}
            >
              Continue
            </button>
          </div>
          <div>
            <h1>Sourcing Answers.</h1>
            <p>
              If Grace can't find a direct answer to your question, she will
              search other sources such as the rradarstation
            </p>
            <Link className='rcm-red-button' to='/'>
              Get Started
            </Link>
          </div>
        </Carousel>
      </React.Fragment>
    );
  }
}
