import * as Cookies from "js-cookie";

export interface Window {
  NativeStorage: any;
  cordova: any;
  [key: string]: any;
}
declare var window: Window;

export enum Methods {
  GET,
  SET
}

export const setToStorage = (key: string, value: string | {} | boolean) => {
  const response = checkStorageTypeAvailable();
  return handleStorage(response, Methods.SET, key, value);
};

export const getFromStorage = (key: string) => {
  const response = checkStorageTypeAvailable();
  return handleStorage(response, Methods.GET, key);
};

export const checkStorageTypeAvailable = () => {
  if (!!window.cordova) {
    if (window.NativeStorage) {
      return "NativeStorage";
    } else {
      return "localStorage";
    }
  }
  return "cookie";
};

export const handleStorage = (
  type: "localStorage" | "NativeStorage" | "cookie",
  method: Methods,
  tag: string,
  value?: string | {}
) => {
  return new Promise((resolve, reject) => {
    if (type !== "localStorage" && type !== "NativeStorage") {
      return method === Methods.SET
        ? Cookies.set(tag, value)
        : Cookies.get(tag);
    }

    if (
      method === Methods.SET &&
      (type === "localStorage" || type === "NativeStorage")
    ) {
      window[type].setItem(tag, value, (response: any) => {
        return resolve(response);
      });
    }

    if (type === "localStorage") {
      const response = window[type].getItem(tag);
      return resolve(response);
    }

    if (type === "NativeStorage") {
      window[type].getItem(
        tag,
        (response: any) => {
          resolve(response);
        },
        (response: any) => {
          resolve(false);
        }
      );
    }
  });
};

// type hasViewedTutorial = boolean;
// type session_token = string;
//
// interface StorageHandler {
//   get(): void;
//   set(): void;
// }
//
// class CookieStorageHandler implements StorageHandler {
//   get() {
//     // do something
//   }
//
//   set() {
//     // do something
//   }
// }
//
// class NativeStorageHandler implements StorageHandler {
//   get() {
//     // do something
//   }
//
//   set() {
//     // do something
//   }
// }
//
// class LocalStorageHandler implements StorageHandler {
//   get() {
//     // do something
//   }
//
//   set() {
//     // do something
//   }
// }
