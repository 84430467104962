import EncoderWav from '../Audio/Encoders/encoder-wav-worker';
import EncoderMp3 from '../Audio/Encoders/encoder-mp3-worker';
import WavAudioEncoder from '../Audio/Encoders/WavAudioEncoder';
import { forceBlobDownload } from '../Audio/forceBlobDownload';
import { sendAudio } from '../authorization';
import { isCordovaBrowser } from '../cordova';

export interface RecorderConfig {
  broadcastAudioProcessEvents: boolean;
  createAnalyserNode: boolean;
  createDynamicsCompressorNode: boolean;
  forceScriptProcessor: boolean;
  manualEncoderId: string;
  micGain: number;
  processorBufferSize: number;
  stopTracksAndCloseCtxWhenFinished: boolean;
  userMediaConstraints: MediaStreamConstraints;
}

export type CustomMessageEvent =
  | null
  | undefined
  | {
      data?: any;
    };

declare var BASE_URL: string;

interface Window {
  MediaRecorder: MediaRecorder;
  webkitAudioContext: MediaRecorder;
}

const defaultConfig: RecorderConfig = {
  broadcastAudioProcessEvents: !isCordovaBrowser(),
  createAnalyserNode: true,
  createDynamicsCompressorNode: false,
  forceScriptProcessor: false,
  manualEncoderId: 'wav', //Switch this to mp3 or ogg
  micGain: 1.0,
  processorBufferSize: 2048,
  stopTracksAndCloseCtxWhenFinished: true,
  userMediaConstraints: { audio: true },
};

class RecorderService {
  public baseUrl: string;
  public em: DocumentFragment;
  public state: string;
  public chunksLength: any;
  public chunks: Array<any>;
  public chunkType: string;
  public usingMediaRecorder: boolean;
  public encoderMimeType: string;
  public config: RecorderConfig;
  public session_token: string;

  public audioCtx: any;
  public micGainNode: GainNode;
  public outputGainNode: GainNode;
  public dynamicsCompressorNode: DynamicsCompressorNode;
  public analyserNode: AnalyserNode;
  public processorNode: ScriptProcessorNode;
  public destinationNode: MediaStreamAudioDestinationNode;
  public micAudioStream: MediaStream;
  public encoderWorker: Worker;
  public inputStreamNode: MediaStreamAudioSourceNode;
  public mediaRecorder: MediaRecorder;
  public hasCordovaAudioInput: boolean;
  public slicing: any;
  public onGraphSetupWithInputStream: any;
  public errorCallback: any;
  public setToTranscribing: any;
  public setToChoosingQuestion: any;
  public setConversationStatus: any;

  constructor() {
    this.baseUrl = '';

    window.AudioContext = window.AudioContext || window.webkitAudioContext;

    this.em = document.createDocumentFragment();

    this.state = 'inactive';
    this.hasCordovaAudioInput = window.audioinput !== undefined ? true : false;
    this.chunks = [];
    this.chunkType = '';
    this.session_token = undefined;
    this.usingMediaRecorder =
      window.MediaRecorder !== undefined || window.MediaRecorder !== null
        ? true
        : false;

    this.encoderMimeType = 'audio/wav';

    this.config = defaultConfig;
  }

  init = (
    baseUrl: string,
    errorCallback: any,
    config?: Partial<RecorderConfig>,
    session_token?: string,
    setToTranscribing?: any,
    setToChoosingQuestion?: any,
    setConversationStatus?: any
  ) => {
    this.baseUrl = baseUrl;
    this.errorCallback = errorCallback;
    this.config = ((config) => {
      if (config === undefined) {
        return defaultConfig;
      }
      return Object.assign({}, defaultConfig, config);
    })(config);
    this.session_token = session_token;
    this.setToTranscribing = setToTranscribing;
    this.setToChoosingQuestion = setToChoosingQuestion;
    this.setConversationStatus = setConversationStatus;
  };

  createWorker(fn: any): Worker {
    var js = fn
      .toString()
      .replace(/^function\s*\(\)\s*{/, '')
      .replace(/}$/, '');
    var blob = new Blob([js]);
    return new Worker(URL.createObjectURL(blob));
  }

  startRecording = (timeslice: any) => {
    console.log('Start Recording!');

    if (this.state !== 'inactive') {
      console.log('Returns because of inactive state');
      return;
    }

    if (!isCordovaBrowser()) {
      console.log('Media Recorder should be set to false!');
      this.usingMediaRecorder = false;
    }

    this.audioCtx = new AudioContext();
    this.micGainNode = this.audioCtx.createGain();
    this.outputGainNode = this.audioCtx.createGain();

    if (this.config.createDynamicsCompressorNode) {
      this.dynamicsCompressorNode = this.audioCtx.createDynamicsCompressor();
    }

    if (this.config.createAnalyserNode) {
      this.analyserNode = this.audioCtx.createAnalyser();
    }

    if (
      this.config.forceScriptProcessor ||
      this.config.broadcastAudioProcessEvents ||
      !this.usingMediaRecorder ||
      this.hasCordovaAudioInput
    ) {
      this.processorNode = this.audioCtx.createScriptProcessor(
        this.config.processorBufferSize,
        1,
        1
      );
    }

    if (this.audioCtx.createMediaStreamDestination) {
      this.destinationNode = this.audioCtx.createMediaStreamDestination();
    } else {
      this.destinationNode = this.audioCtx.destination;
    }

    // Create web worker for doing the encoding
    if (!this.usingMediaRecorder) {
      if (this.config.manualEncoderId === 'mp3') {
        // This also works and avoids weirdness imports with workers
        // this.encoderWorker = new Worker(BASE_URL + '/workers/encoder-ogg-worker.js')
        this.encoderWorker = this.createWorker(EncoderMp3);
        this.encoderWorker.postMessage([
          'init',
          { baseUrl: './', sampleRate: this.audioCtx.sampleRate },
        ]);
        this.encoderMimeType = 'audio/mpeg';
      } else {
        this.encoderWorker = this.createWorker(EncoderWav);
        this.encoderMimeType = 'audio/wav';
      }
    }

    // This will prompt user for permission if needed
    if (isCordovaBrowser()) {
      return navigator.mediaDevices
        .getUserMedia(this.config.userMediaConstraints)
        .then((stream) => {
          this._startRecordingWithStream(stream, timeslice);
        })
        .catch(() => {
          this._startCordovaAudioInput(timeslice);
        });
    } else {
      if (window.audioinput !== undefined) {
        window.audioinput.checkMicrophonePermission((hasPermission: any) => {
          if (hasPermission) {
            this._startCordovaAudioInput(timeslice);
          } else {
            window.audioinput.getMicrophonePermission(function (
              hasPermission: any,
              message: any
            ) {
              if (hasPermission) {
                this._startCordovaAudioInput(timeslice);
              } else {
              }
            });
          }
        });
      }
    }
  };

  setMicGain = (newGain: any) => {
    this.config.micGain = newGain;
    if (this.audioCtx && this.micGainNode) {
      this.micGainNode.gain.setValueAtTime(newGain, this.audioCtx.currentTime);
    }
  };

  _startCordovaAudioInput = (timeslice: any) => {
    window.audioinput.start({
      audioContext: this.audioCtx,
      streamToWebAudio: false,
      sampleRate: window.audioinput.SAMPLERATE.VOIP_16000Hz,
      bufferSize: 8192,
      format: window.audioinput.FORMAT.PCM_16BIT,
      channels: window.audioinput.CHANNELS.MONO,
    });

    if (window.audioinput.isCapturing()) {
      this.state = 'recording';
      this.destinationNode = this.audioCtx.createMediaStreamDestination();
      this._startRecordingWithStream(this.destinationNode.stream, timeslice);
      window.audioinput.connect(this.destinationNode);
    }

    window.addEventListener('audioinput', this._handleAudioInput, false);

    window.addEventListener(
      'audioinputerror',
      function (data) {
        console.log('Error: ', data);
      },
      false
    );
  };

  _handleAudioInput = (event: any) => {
    try {
      if (event && event.data) {
        this.chunksLength += event.data.length;
        this.chunks.push(...event.data);
      } else {
        alert('Unknown audioinput event!');
      }
    } catch (ex) {
      alert('onAudioInputCapture ex: ' + ex);
    }
  };

  _startRecordingWithStream = (stream: MediaStream, timeslice: any) => {
    this.micAudioStream = stream;
    this.inputStreamNode = this.audioCtx.createMediaStreamSource(
      this.micAudioStream
    );
    this.audioCtx = this.inputStreamNode.context;

    if (this.onGraphSetupWithInputStream) {
      this.onGraphSetupWithInputStream(this.inputStreamNode);
    }

    this.inputStreamNode.connect(this.micGainNode);
    this.micGainNode.gain.setValueAtTime(
      this.config.micGain,
      this.audioCtx.currentTime
    );

    let nextNode: any = this.micGainNode;
    if (this.dynamicsCompressorNode) {
      this.micGainNode.connect(this.dynamicsCompressorNode);
      nextNode = this.dynamicsCompressorNode;
    }

    this.state = 'recording';

    if (this.processorNode) {
      nextNode.connect(this.processorNode);
      this.processorNode.connect(this.outputGainNode);
      this.processorNode.onaudioprocess = (e) => {
        this._onAudioProcess(e);
      };
    } else {
      nextNode.connect(this.outputGainNode);
    }

    if (this.analyserNode) {
      nextNode.connect(this.analyserNode);
    }

    this.outputGainNode.connect(this.destinationNode);

    if (this.usingMediaRecorder) {
      this.mediaRecorder = new MediaRecorder(this.destinationNode.stream, {
        mimeType: 'audio/webm',
      });

      this.mediaRecorder.addEventListener('dataavailable', (evt) => {
        this._onDataAvailable(evt);
      });

      this.mediaRecorder.addEventListener('error', (evt) => this._onError(evt));

      this.mediaRecorder.start(timeslice);
    } else {
      // Output gain to zero to prevent feedback. Seems to matter only on Edge, though seems like should matter
      // on iOS too.  Matters on chrome when connecting graph to directly to audioCtx.destination, but we are
      // not able to do that when using MediaRecorder.
      this.outputGainNode.gain.setValueAtTime(0, this.audioCtx.currentTime);
      // this.outputGainNode.gain.value = 0

      // Todo: Note that time slicing with manual wav encoderWav won't work. To allow it would require rewriting the encoderWav
      // to assemble all chunks at end instead of adding header to each chunk.
      if (timeslice) {
        this.slicing = setInterval(function () {
          if (this.state === 'recording') {
            this.encoderWorker.postMessage(['dump', this.audioCtx.sampleRate]);
          }
        }, timeslice);
      }
    }
  };

  _onAudioProcess = (e: any) => {
    if (this.config.broadcastAudioProcessEvents) {
      this.em.dispatchEvent(
        new CustomEvent('onaudioprocess', {
          detail: {
            inputBuffer: e.inputBuffer,
            outputBuffer: e.outputBuffer,
          },
        })
      );
    }

    if (!this.usingMediaRecorder) {
      if (this.state === 'recording') {
        if (this.config.broadcastAudioProcessEvents) {
          this.encoderWorker.postMessage([
            'encode',
            e.outputBuffer.getChannelData(0),
          ]);
        } else {
          this.encoderWorker.postMessage([
            'encode',
            e.inputBuffer.getChannelData(0),
          ]);
        }
      }
    }
  };

  stopRecording = () => {
    if (window.audioinput !== undefined && !isCordovaBrowser()) {
      window.audioinput.stop((e: any) => {});

      window.removeEventListener('audioinput', this._handleAudioInput, false);
      this.state = 'inactive';
      var encoder = new WavAudioEncoder(16000, 1);
      encoder.encode([this.chunks]);
      var blob = encoder.finish('audio/wav');
      this.setToTranscribing();
      sendAudio(this.session_token, blob, 'wav')
        .then((res) => res.json())
        .then((res) => this._handleSpokenResponse(res))
        .catch((res) => console.warn(res));
      this._reset();
    }
    if (this.usingMediaRecorder) {
      this.state = 'inactive';
      this.mediaRecorder.stop();
    } else if (window.audioinput === undefined) {
      this.state = 'inactive';
      this.encoderWorker.postMessage(['dump', this.audioCtx.sampleRate]);
      clearInterval(this.slicing);
    }
  };

  _handleSpokenResponse = (response: any) => {
    const { status, error } = response;
    switch (status) {
      case 'choosing_question':
        this.setToTranscribing();
        this.setToChoosingQuestion();
        this.setConversationStatus(status);
        break;
      case 'google_cloud_speech_recognition_error':
        this.setToTranscribing();
        this.setConversationStatus(status);
        this.errorCallback(
          true,
          "Sorry, your question couldn't be recognised, please try again."
        );
        break;
      default:
        this.setToTranscribing();
        this.setConversationStatus(status);
        console.log(status);
        break;
    }
  };

  _onDataAvailable = (evt: any) => {
    this.chunks.push(evt.data);
    this.chunkType = evt.data.type;
    if (this.state !== 'inactive') {
      return;
    }
    let blob: Blob = new Blob(this.chunks, { type: this.chunkType });
    let encoding = !this.usingMediaRecorder ? 'wav' : 'webm';

    // Comment out when publishing
    // forceBlobDownload(blob, `audio.${encoding}`);
    this.setToTranscribing();
    sendAudio(this.session_token, blob, encoding)
      .then((res) => res.json())
      .then((res) => this._handleSpokenResponse(res))
      .catch((res) => console.warn(res));

    let blobUrl = URL.createObjectURL(blob);
    const recording = {
      ts: new Date().getTime(),
      blobUrl: blobUrl,
      mimeType: blob.type,
      size: blob.size,
    };

    this._reset();

    this.em.dispatchEvent(
      new CustomEvent('recording', { detail: { recording: recording } })
    );
  };

  _onError = (evt: any) => {
    this.em.dispatchEvent(new Event('error'));
    alert('error:' + evt); // for debugging purposes
  };

  _reset = () => {
    this.chunks = [];
    this.chunksLength = null;
    this.chunkType = null;

    if (this.destinationNode) {
      this.destinationNode.disconnect();
      this.destinationNode = null;
    }
    if (this.outputGainNode) {
      this.outputGainNode.disconnect();
      this.outputGainNode = null;
    }
    if (this.analyserNode) {
      this.analyserNode.disconnect();
      this.analyserNode = null;
    }
    if (this.processorNode) {
      this.processorNode.disconnect();
      this.processorNode = null;
    }
    if (this.encoderWorker) {
      this.encoderWorker.postMessage(['close']);
      this.encoderWorker = null;
    }
    if (this.dynamicsCompressorNode) {
      this.dynamicsCompressorNode.disconnect();
      this.dynamicsCompressorNode = null;
    }
    if (this.micGainNode) {
      this.micGainNode.disconnect();
      this.micGainNode = null;
    }
    if (this.inputStreamNode) {
      this.inputStreamNode.disconnect();
      this.inputStreamNode = null;
    }

    if (this.config.stopTracksAndCloseCtxWhenFinished) {
      // This removes the red bar in iOS/Safari
      console.log('Mic Audio Stream: ', this.micAudioStream.getTracks());
      new Promise((resolve, reject) => {
        this.micAudioStream.getTracks().forEach((track) => track.stop());
        resolve(true);
      }).then(() => {
        this.micAudioStream = null;
      });

      this.audioCtx.close();
      this.audioCtx = null;

      if (this.hasCordovaAudioInput) {
        window.audioinput.disconnect(this.destinationNode);
        window.audioinput.audioContext = null;
      }
    }
  };
}

export default new RecorderService();
