import * as React from 'react';
import MicrophoneButton from './buttons/MicrphoneButton';
import InputModeToggle from './buttons/InputModeToggle';
// @ts-ignore: fontawesome import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface FooterProps {
  handleInputMode: (e: any) => void;
  userInputDisplay: boolean;
  voiceOverlay: boolean;
  handleButtonClick: (e: any) => void;
}

class Footer extends React.Component<FooterProps> {
  render() {
    return (
      <div className='grace-footer'>
        <div
          className={
            'grace-footer-internal ' +
            (this.props.userInputDisplay
              ? 'keyboard-layout'
              : 'microphone-layout')
          }
        >
          <button id='grace-footer-menu-button'>
            <FontAwesomeIcon icon={['fas', 'th']} />
          </button>

          <MicrophoneButton
            userInputDisplay={this.props.userInputDisplay}
            voiceOverlay={this.props.voiceOverlay}
            handleButtonClick={this.props.handleButtonClick}
          />
          {/* {window.cordova.platformId !== "browser" ? ( */}
          <InputModeToggle
            handleInputMode={this.props.handleInputMode}
            userInputDisplay={this.props.userInputDisplay}
          />
          {/* ) : null} */}

          <div
            className={this.props.voiceOverlay ? 'grace-touch-active' : ''}
          />
        </div>
      </div>
    );
  }
}

export default Footer;
