import * as React from "react";
import classNames from "classnames";
import { isCordovaBrowser } from "../helpers/cordova";

export interface AudioOverlayProps {
  forwardRef: React.Ref<HTMLCanvasElement>;
  voiceOverlay: boolean;
  userInput: string;
}

class AudioOverlay extends React.Component<AudioOverlayProps, null> {
  render() {
    var noDisplay = {
      display: "none"
    };

    return (
      <div
        className={classNames({
          "grace-chat-voice-input": true,
          "grace-chat-voice-background": !isCordovaBrowser()
        })}
        style={this.props.voiceOverlay === false ? noDisplay : null}
      >
        <img src="assets/img/GraceListening.gif" />
        <span className="grace-chat-voice-message">Try Asking A Question!</span>
        <span className="grace-chat-voice-instruction">
          {window.navigator.platform === "iPad" ||
          window.navigator.platform === "iPhone" ? (
            <strong>Press The Stop Button To Submit</strong>
          ) : (
            <strong>Press The Stop Button To Submit</strong>
          )}
        </span>
        <canvas id="streamVisualizer" ref={this.props.forwardRef} />
        <span className="grace-chat-response">
          {this.props.userInput || ""}
        </span>
        <span className="grace-voice-animation sound-wave-one" />
        <span className="grace-voice-animation sound-wave-two" />
        <span className="grace-voice-animation sound-wave-three" />
      </div>
    );
  }
}

export default AudioOverlay;
