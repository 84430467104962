import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface InputModeToggleProps {
  handleInputMode: (e: any) => void;
  userInputDisplay: boolean;
}

const InputModeToggle: React.SFC<InputModeToggleProps> = (
  props: InputModeToggleProps
) => (
  <button
    id='grace-footer-keyboard-button'
    onClick={(e) => props.handleInputMode(e)}
  >
    <FontAwesomeIcon
      icon={
        props.userInputDisplay ? ['fas', 'microphone'] : ['fas', 'keyboard']
      }
    />
  </button>
);

export default InputModeToggle;
