import * as React from 'react';
import { NavLink } from 'react-router-dom';
export interface UserProfileProps {
  firstName: string;
  lastName: string;
  companyName?: string;
  canSwitchCompany?: boolean;
}

const UserProfile: React.SFC<UserProfileProps> = (props: UserProfileProps) => (
  <div className='grace-user-profile'>
    <div className='grace-user-circle'>
      {props.firstName !== undefined ? (
        <span>{props.firstName.charAt(0)}</span>
      ) : null}
    </div>
    <div className='grace-user-info'>
      <div className='grace-user-name'>
        {props.firstName !== undefined
          ? props.firstName.charAt(0) + ' ' + props.lastName
          : null}
      </div>
      {props.canSwitchCompany && (
        <NavLink to={'/auth/company_select'}>
          {' '}
          ({props.companyName}) Switch
        </NavLink>
      )}
    </div>
  </div>
);

export default UserProfile;
