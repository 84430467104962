import * as React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import { HelpScreenProps } from './helpprops';
// @ts-ignore: fontawesome import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/fontawesome-free-solid';

class HelpScreen extends React.Component<HelpScreenProps> {
  render() {
    return (
      <div className='grace-view-container'>
        <Header
          handleClick={this.props.toggleMenu}
          menuPosition={this.props.menuPosition}
        />
        <div className='grace-padding grace-overflow'>
          <div className='grace-body'>
            <h2>Help With Grace</h2>
            <h3>Asking Questions</h3>
            <p>
              There are two modes available for asking questions of grace,
              Speech-To-Text, and Text only.
            </p>
            <hr />
            <h4>
              <FontAwesomeIcon icon={['fas', 'check-circle']} />
              Speech To Text
            </h4>
            <p>
              Pressing &amp; Holding the microphone icon allows you to use your
              devices microphone to ask Grace a question directly.
            </p>
            <hr />
            <h4>
              <FontAwesomeIcon icon={['fas', 'check-circle']} />
              Text Only
            </h4>
            <p>
              Typing mode allows you to ask questions of grace by just typing a
              question.
            </p>
            <hr />
            <h3>See The Tutorial Screens Again?</h3>
            <p>
              Want to see the applications initial welcome tutorial again? No
              problem, hit the button below.
            </p>
            <Link className='rcm-red-button' to='/tutorial'>
              Play Again
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default HelpScreen;
