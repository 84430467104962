export const postAuthorizationTokens = (auth_token?: string, session_token?: string) => {
  var data = {
    auth_token : auth_token !== '' ? auth_token : '',
    session_token: session_token !== '' ? session_token : ''
  }
  console.log('Posting Authorization Tokens: ', window.opener);
  window.opener.postMessage(
    JSON.stringify(data),
    "*"
  )
}