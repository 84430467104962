import { library } from '@fortawesome/fontawesome-svg-core';
import { faKey } from '@fortawesome/fontawesome-free-solid';
const faTh = require('@fortawesome/fontawesome-free-solid/faTh');
const faMicrophone = require('@fortawesome/fontawesome-free-solid/faMicrophone');
const faSquare = require('@fortawesome/fontawesome-free-solid/faSquare');
const faPaperPlane = require('@fortawesome/fontawesome-free-solid/faPaperPlane');
const faComments = require('@fortawesome/fontawesome-free-solid/faComments');
const faQuestionCircle = require('@fortawesome/fontawesome-free-solid/faQuestionCircle');
const faWrench = require('@fortawesome/fontawesome-free-solid/faWrench');
const faInfoCircle = require('@fortawesome/fontawesome-free-solid/faInfoCircle');
const faExclamationCircle = require('@fortawesome/fontawesome-free-solid/faExclamationCircle');
const faCheckCircle = require('@fortawesome/fontawesome-free-solid/faCheckCircle');
const faKeyboard = require('@fortawesome/fontawesome-free-solid/faKeyboard');
library.add(
  faTh,
  faMicrophone,
  faPaperPlane,
  faSquare,
  faComments,
  faQuestionCircle,
  faWrench,
  faInfoCircle,
  faExclamationCircle,
  faCheckCircle,
  faKeyboard
);
