import * as React from "react";

const ConnectionOverlay = () => {
  return (
    <div className="connectionOverlay">
      <div className={"spinner spinner--relative"}>
        <div className="dbl-spinner" />
        <div className="dbl-spinner dbl-spinner--2" />
      </div>
      <h2>
       Attempting to reconnect to grace
      </h2>
      <p>
        We suggest waiting whilst we try to establish a connection, or
        alternatively please check your connection settings.
      </p>
    </div>
  );
};

export default ConnectionOverlay;
