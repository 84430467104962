import * as React from 'react';
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
var createHashHistory = require('history').createBrowserHistory;
import {
  AppScreen,
  AuthScreen,
  AuthSuccess,
  AboutScreen,
  SettingsScreen,
  TutorialScreen,
  HistoryScreen,
  HelpScreen,
} from './screens';
import MenuContainer from './containers/MenuContainer';
import ConnectionOverlay from './components/ConnectionOverlay';
import { setToStorage } from './helpers/storage';
import { sendAuthentication } from './helpers/authorization';

export interface Window {
  NativeStorage: any;
  cordova: any;
  [key: string]: any;
}
declare var window: Window;

export interface ApplicationProps {
  hasViewedTutorial: any;
  voiceOutput: any;
  notificationPop: any;
  userDetails: any;
  session_token: any;
  defaultToInputDisplay: boolean;
}

export interface ApplicationState {
  menuOpen: boolean;
  hammer: null | HammerManager;
  voiceOutput: boolean;
  hasViewedTutorial: boolean;
  notificationPop: boolean;
  allowMenuSwipe: boolean;
  menuPosition: number;
  userDetails?: {
    first_name: string;
    last_name: string;
  };
  connectionScreen: boolean;
  menuInit: boolean;
  auth_token?: string;
  session_token?: string;
}

class Application extends React.Component<ApplicationProps, ApplicationState> {
  constructor(props: ApplicationProps) {
    super(props);
  }

  public state: ApplicationState = {
    menuOpen: false,
    hammer: null,
    voiceOutput: this.props.voiceOutput || true,
    hasViewedTutorial: this.props.hasViewedTutorial,
    notificationPop: this.props.notificationPop || true,
    allowMenuSwipe: false,
    menuPosition: 375,
    userDetails: JSON.parse(this.props.userDetails) || {
      first_name: '',
      last_name: '',
    },
    connectionScreen: false,
    menuInit: true,
    session_token: this.props.session_token,
  };

  public history = createHashHistory().listen(() => {
    if (this.state.menuInit === false) {
      this.toggleMenu(true);
    }
  });

  public menuButtonClick = () => {
    this.setState({
      menuInit: false,
    });
    this.toggleMenu();
  };

  public toggleMenu = (toOpenMenu?: any) => {
    const HiddenPixelCount = 375;
    toOpenMenu === false
      ? this.setState((prevState: ApplicationState) => ({
          menuOpen: toOpenMenu,
          menuPosition: prevState.menuPosition === 0 ? 0 : HiddenPixelCount,
          allowMenuSwipe: false,
        }))
      : this.setState((prevState: ApplicationState) => ({
          menuOpen: !prevState.menuOpen,
          menuPosition: prevState.menuPosition === 0 ? HiddenPixelCount : 0,
          allowMenuSwipe: false,
        }));
  };

  public toggleVoice = () => {
    this.setState(
      (prevState) => ({
        voiceOutput: !prevState.voiceOutput,
      }),
      () => setToStorage('voiceOutput', this.state.voiceOutput)
    );
  };

  public togglePop = () => {
    this.setState(
      (prevState) => ({
        notificationPop: !prevState.notificationPop,
      }),
      () => setToStorage('notificationPop', this.state.notificationPop)
    );
  };

  public clearSettings = () => {
    var options = {
      androidTheme: window.plugins.actionsheet.ANDROID_THEMES.THEME_HOLO_DARK,
      title: 'Reset Settings To Default?',
      subtitle: 'Resetting Will Log You Out',
      buttonLabels: ['Yes, Reset'],
      androidEnableCancelButton: true,
      winphoneEnableCancelButton: true,
      addCancelButtonWithLabel: 'Cancel',
      destructiveButtonLast: false,
    };
    window.plugins.actionsheet.show(options, (buttonIndex: any) => {
      console.log('Response From Action :', buttonIndex);
    });
    // window.NativeStorage.clear();
  };

  public setTutorialToComplete = () => {
    this.setState({
      hasViewedTutorial: true,
    });
  };

  public setAuthTokenInState = async (value: string) => {
    await this.getSessionTokenFromAuthenticate(value);
    this.setState(
      () => ({
        auth_token: value,
      }),
      () => {
        if (this.state.hasViewedTutorial === true) {
          window.location.hash = '#/';
        } else {
          window.location.hash = '#/tutorial';
        }
      }
    );
  };

  public getSessionTokenFromAuthenticate = (value: string) => {
    console.log('Get Auth Token from State!: ', value);
    return new Promise((resolve, reject) => {
      sendAuthentication(value)
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          console.log('Auth Response: ', response);

          return this.setUserFromAuthenticateToStorage(response);
        })
        .then((response) => {
          const sessionToken = response.session_token;
          setToStorage('session_token', sessionToken);
          this.setState({
            session_token: sessionToken,
          });
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  public onConnectionErrorCallback = () => {
    this.setState({
      connectionScreen: true,
    });
  };

  public onConnectionReturned = () => {
    console.log('onConnectionReturned Fired!');
    this.setState({
      connectionScreen: false,
    });
  };

  private setUserFromAuthenticateToStorage = (response: any) => {
    if (response.status !== 'authenticated') {
      window.location.hash = '#/auth';
    } else {
      const userDetails = {
        first_name: response.user_first_name || response.first_name,
        last_name: response.user_last_name || response.last_name,
        company_name: response.company_name,
        company_can_switch: response.company_can_switch,
      };
      setToStorage('userDetails', JSON.stringify(userDetails));
      this.setState({
        userDetails: userDetails,
      });
      return response;
    }
  };

  public componentDidMount() {}

  render() {
    return (
      <div className='grace-wrapper'>
        <Router>
          <Route
            render={({ location }) => (
              <React.Fragment>
                <MenuContainer
                  currentState={this.state.menuOpen}
                  handleClick={this.menuButtonClick}
                  menuPosition={this.state.menuPosition}
                  userDetails={this.state.userDetails}
                />
                <TransitionGroup className='grace-view-master-wrapper'>
                  <CSSTransition
                    key={location.pathname.split('/')[1]}
                    classNames='fade'
                    timeout={600}
                    exit={false}
                  >
                    <Switch location={location}>
                      <Route
                        exact
                        key={location.pathname}
                        path='/tutorial'
                        render={() => (
                          <TutorialScreen
                            key={location.pathname}
                            menuPosition={this.state.menuPosition}
                            setTutorialToComplete={this.setTutorialToComplete}
                          />
                        )}
                      />

                      <Route
                        key={location.pathname}
                        path='/auth/:companyselect'
                        render={() => (
                          <AuthScreen
                            key={location.pathname}
                            menuPosition={this.state.menuPosition}
                            setAuthTokenInState={this.setAuthTokenInState}
                            onConnectionErrorCallback={
                              this.onConnectionErrorCallback
                            }
                            onConnectionReturned={this.onConnectionReturned}
                          />
                        )}
                      />

                      <Route
                        key={location.pathname}
                        path='/auth'
                        render={() => (
                          <AuthScreen
                            key={location.pathname}
                            menuPosition={this.state.menuPosition}
                            setAuthTokenInState={this.setAuthTokenInState}
                            onConnectionErrorCallback={
                              this.onConnectionErrorCallback
                            }
                            onConnectionReturned={this.onConnectionReturned}
                          />
                        )}
                      />

                      <Route
                        exact
                        key={location.pathname}
                        path='/authsuccess'
                        render={() => (
                          <AuthSuccess
                            key={location.pathname}
                            menuPosition={this.state.menuPosition}
                            setAuthTokenInState={this.setAuthTokenInState}
                          />
                        )}
                      />

                      <Route
                        exact
                        key={location.pathname}
                        path='/'
                        render={() =>
                          this.state.session_token ? (
                            <AppScreen
                              getSessionToken={
                                this.getSessionTokenFromAuthenticate
                              }
                              toggleMenu={this.menuButtonClick}
                              key={location.pathname}
                              voiceOutput={this.state.voiceOutput}
                              popOutput={this.state.notificationPop}
                              menuPosition={this.state.menuPosition}
                              defaultToInputDisplay={
                                this.props.defaultToInputDisplay
                              }
                              auth_token={this.state.auth_token}
                              session_token={this.state.session_token}
                              userDetails={this.state.userDetails}
                              onConnectionErrorCallback={
                                this.onConnectionErrorCallback
                              }
                              onConnectionReturned={this.onConnectionReturned}
                            />
                          ) : (
                            <Redirect to='/auth' />
                          )
                        }
                      />
                      <Route
                        exact
                        key={location.pathname}
                        path='/about'
                        render={() => (
                          <AboutScreen
                            toggleMenu={this.menuButtonClick}
                            key={location.pathname}
                            menuPosition={this.state.menuPosition}
                          />
                        )}
                      />
                      <Route
                        exact
                        key={location.pathname}
                        path='/settings'
                        render={() => (
                          <SettingsScreen
                            toggleMenu={this.menuButtonClick}
                            toggleVoice={this.toggleVoice}
                            togglePop={this.togglePop}
                            voiceOutput={this.state.voiceOutput}
                            popOutput={this.state.notificationPop}
                            key={location.pathname}
                            menuPosition={this.state.menuPosition}
                            clearSettings={this.clearSettings}
                          />
                        )}
                      />
                      <Route
                        exact
                        key={location.pathname}
                        path='/history'
                        render={() => (
                          <HistoryScreen
                            toggleMenu={this.menuButtonClick}
                            key={location.pathname}
                            menuPosition={this.state.menuPosition}
                          />
                        )}
                      />
                      <Route
                        exact
                        key={location.pathname}
                        path='/help'
                        render={() => (
                          <HelpScreen
                            toggleMenu={this.menuButtonClick}
                            key={location.pathname}
                            menuPosition={this.state.menuPosition}
                          />
                        )}
                      />
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              </React.Fragment>
            )}
          />
        </Router>
        {this.state.connectionScreen ? <ConnectionOverlay /> : null}
      </div>
    );
  }
}

export default Application;
