import graceAPI from '@rradar/grace-api';
import { lt, major } from 'semver';

export default class Version {
  static get = async (session_token: string) => {
    const api = new graceAPI();
    api.setEndpoint(REACT_APP_API_URL);
    api.setSessionToken(session_token);
    return api.actions().version();
  };

  static lessThanMajor = (
    localExpectation: string,
    remoteVersion: string
  ): boolean => {
    return major(localExpectation) < major(remoteVersion) ? true : false;
  };

  static lessThan = (localExpectation: string, remoteVersion: string) => {
    return lt(localExpectation, remoteVersion);
  };
}
