export const isCordovaBrowser = () => {
  if (window.cordova && window.cordova.platformId === 'browser') {
    return true;
  }
  return false;
};

export const isCordova = () => {
  return window.hasOwnProperty('cordova');
};
