export const fetchData = async (endpoint: string, data: {}) => {
  try{
    const response = await fetch(endpoint, data).catch(() => {throw (new Error())});

    const json = await response.json();
    return response.ok ? json : Promise.reject(json);
  } catch(error) {
    fetchDataErrorHandler(error);
  }
};


export const fetchDataErrorHandler = (error: Error) => {
  throw new Error
}