export interface FormProps {
  submitToChatClient: (e: any) => void;
  handleInputChange: (e: any) => void;
  userInput: string;
  userInputDisplay: boolean;
}

export interface FormState {}

import * as React from "react";

class Form extends React.Component<FormProps, FormState> {
  inputField: React.RefObject<HTMLInputElement>;
  constructor(props: FormProps) {
    super(props);
    this.inputField = React.createRef();
  }

  public state = {};

  componentWillReceiveProps(nextProps: FormProps) {
    // if (nextProps.userInputDisplay === true) {
    //   setTimeout(() => {
    //     this.inputField.current.focus();
    //   }, 500);
    // }
  }

  render() {
    return (
      <form
        className={
          "grace-chat-input " +
          (this.props.userInputDisplay ? "grace-chat-up" : "")
        }
        onSubmit={e => this.props.submitToChatClient(e)}
      >
        <input
          type="text"
          value={this.props.userInput}
          onChange={e => this.props.handleInputChange(e.target.value)}
          id="grace-input"
          autoFocus={this.props.userInputDisplay}
          ref={this.inputField}
        />
      </form>
    );
  }
}

export default Form;
