import * as React from 'react';
import { AuthSuccessProps, AuthSuccessState, User } from './authimports';
import 'url-search-params-polyfill';
import { postAuthorizationTokens } from '../../helpers/postMessage';
const { detect } = require('detect-browser');
const browser = detect();

class AuthSuccess extends React.Component<AuthSuccessProps, AuthSuccessState> {
  constructor(props: AuthSuccessProps) {
    super(props);
  }

  public state = {
    auth_token: '',
  };

  private getAuthToken = (): string => {
    const urlParams = new URLSearchParams(window.location.href);
    const auth_token = urlParams.getAll('auth_token')[0];
    if (window.opener && window.opener !== window) {
      return;
    }
    this.props.setAuthTokenInState(auth_token);
    return auth_token;
  };

  componentDidMount() {
    console.log('####### Auth_Sucess.tsx ######');
    let token = this.getAuthToken();
    switch (browser && browser.name) {
      case 'edge':
        if (window.opener !== undefined) {
          console.log('Edge Browser and window.opener does not equate to null');
          postAuthorizationTokens(token, '');
        }
        break;

      default:
        if (window.opener !== null) {
          console.log('Edge Browser and window.opener does not equate to null');
          postAuthorizationTokens(token, '');
        }
    }
  }

  render() {
    return (
      <div className='grace-view-container'>
        <div className='grace-padding'>
          <div className='grace-sign-in'>
            <h3>Signing You In</h3>
          </div>
          <div className={'spinner'}>
            <div className='dbl-spinner' />
            <div className='dbl-spinner dbl-spinner--2' />
          </div>
        </div>
      </div>
    );
  }
}

export default AuthSuccess;
