import { fetchData } from './fetch';
import {
  processFormData,
  getFileTypeFromMimeType,
} from './Audio/processFormData';

export interface Window {
  NativeStorage: any;
  cordova: any;
  [key: string]: any;
}
declare var window: Window;

export const sendAuthentication = (bodyData: string) => {
  return fetch(`${REACT_APP_API_URL}/authenticate`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
    },
    body: JSON.stringify({ auth_token: bodyData }),
  });
};

export const getAuthenticationURL = () => {
  return fetchData(`${REACT_APP_API_URL}/auth_url`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
    },
  }).catch((err) => {
    throw err;
  });
};

export const sendAudio = (
  authorization: string,
  audioData: Blob,
  encoding: string
) => {
  const fileType = getFileTypeFromMimeType(audioData.type);
  const formData = processFormData(audioData, fileType);
  return fetch(`${REACT_APP_API_URL}/spoken?audio_encoding=${encoding}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      authorization: authorization,
    },
    body: formData,
  }).catch((error) => {
    throw error;
  });
};
