import * as React from 'react';
import Header from '../../components/Header';
import { AboutScreenProps } from './aboutprops';

class AboutScreen extends React.Component<AboutScreenProps> {
  render() {
    return (
      <div className="grace-view-container">
        <Header handleClick={this.props.toggleMenu} menuPosition={this.props.menuPosition} />
        <div className="grace-padding grace-overflow">
          <div className="grace-body">
            <h2>About rradargrace</h2>
            <p>
              rradargrace is a virtual legal assistant, designed to provide quick, convenient access to award winning
              legal support and answer FAQs for employment, <em>HR</em>, <em>health and safety</em> laws and
              regulations. rradargrace delivers template procedures, assessments and other useful documents directly to
              a smart phone or tablet.
            </p>
            <p>
              ​The voice-activated app gives easy-access legal support whether you're &apos;on-the-go&apos; or in the
              office; a handy and useful tool for busy, modern businesses.
            </p>
            <hr />
            <h2>About rradar</h2>
            <p>
              Businesses deserve a fairer experience of the law. Through education, collaboration, expertise, innovation
              and a real-world understanding of a client's needs, we can achieve this. rradar is not a traditional law
              firm and we are very proud of that. ​
              <br />
              <br />
              <em>#WeChangeThings</em>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutScreen;
