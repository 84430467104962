import * as React from 'react';

export interface SwitchProps {
  id: string;
  name: string;
  title: string;
  label: string;
  labelRight?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  theme?: string;
  checked: boolean;
  mode?: string;
  onChange: (state: any) => any;
}

export interface SwitchState {
  checked: boolean;
}

class Switch extends React.Component<SwitchProps, SwitchState> {
  constructor(props: SwitchProps) {
    super(props);

    this.state = {
      checked:
        this.props.defaultChecked === null
          ? this.props.checked
          : this.props.defaultChecked
    };
  }

  render() {
    const mode =
      ['switch', 'select'].indexOf(this.props.mode) < -1
        ? 'switch'
        : this.props.mode;

    const id = this.props.id.length > 0 ? this.props.id : this.props.name;

    const classes = [
      'grace-switch-button',
      `grace-mode-${mode}`,
      this.props.theme,
      this.props.disabled ? ' disabled' : ''
    ];

    const label = () => {
      if (this.props.label.length > 0) {
        return <label htmlFor={id}>{this.props.label}</label>;
      }

      return null;
    };

    const labelRight = () => {
      if (this.props.labelRight.length > 0) {
        return <label htmlFor={id}>{this.props.labelRight}</label>;
      }

      return null;
    };

    return (
      <div className={classes.join(' ').trim()}>
        {label}
        <input
          onChange={this.onChange.bind(this)}
          checked={this.state.checked}
          disabled={this.props.disabled}
          id={id}
          name={this.props.name}
          type="checkbox"
          value="1"
        />
        <label htmlFor={id} />
        {labelRight}
      </div>
    );
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      checked:
        nextProps.checked !== this.state.checked
          ? nextProps.checked
          : this.state.checked
    });
  }

  onChange() {
    this.props.onChange(!this.state.checked);

    this.setState({
      checked: !this.state.checked
    });
  }
}

export default Switch;
