import * as React from 'react';
import { NavLink } from 'react-router-dom';
import UserProfile from '../components/Userprofile';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface MenuContainerProps {
  currentState: boolean;
  handleClick: () => void;
  menuPosition: number;
  userDetails: {
    first_name: string;
    last_name: string;
    company_name?: string;
    company_can_switch?: boolean;
  };
}

class MenuContainer extends React.Component<MenuContainerProps> {
  constructor(props: MenuContainerProps) {
    super(props);
  }

  componentDidMount() {
    console.log('User Details Props: ', this.props.userDetails);
  }
  render(): JSX.Element {
    let menuStyle = {
      transform: `translateX(${this.props.menuPosition}px)`,
    };
    const copyright = String.fromCharCode(169);
    return (
      <div
        className={
          'grace-menu-container ' + (this.props.currentState ? 'menu-open' : '')
        }
        style={menuStyle}
      >
        <header className='grace-menu-header'>
          <UserProfile
            firstName={this.props.userDetails.first_name}
            lastName={this.props.userDetails.last_name}
            canSwitchCompany={this.props.userDetails.company_can_switch}
            companyName={this.props.userDetails.company_name}
          />
          <button
            className={
              'hamburger hamburger--arrow-r' +
              (this.props.menuPosition === 0 ? ' is-active' : '')
            }
            onClick={(e) => this.props.handleClick()}
            type='button'
          >
            <span className='hamburger-box'>
              <span className='hamburger-inner' />
            </span>
          </button>
        </header>
        <ul className='grace-menu-list'>
          <li>
            <NavLink to='/' exact activeClassName='active'>
              <FontAwesomeIcon icon={['fas', 'comments']} />
              Ask Grace
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/history" exact activeClassName="active">
              <FontAwesomeIcon icon={faHistory} />
              My History
            </NavLink>
          </li> */}
          <li>
            <NavLink to='/help' exact activeClassName='active'>
              <FontAwesomeIcon icon={['fas', 'question-circle']} />
              Help
            </NavLink>
          </li>
          <li>
            <NavLink to='/settings' exact activeClassName='active'>
              <FontAwesomeIcon icon={['fas', 'wrench']} />
              App Settings
            </NavLink>
          </li>
          <li>
            <NavLink to='/about' exact activeClassName='active'>
              About
              <FontAwesomeIcon icon={['fas', 'info-circle']} />
            </NavLink>
          </li>
        </ul>

        <div className='grace-menu-footer'>
          <img src='assets/img/emblem.svg' width='75' />
          <small>{copyright} 2020 All Rights Reserved.</small>
          <br />
          <small>
            <a href='https://www.rradar.com/terms-and-conditions'>
              Terms and Conditions
            </a>
          </small>
          <br />
          <small>
            <a href='https://www.rradar.com/privacy-policy'>Privacy Policy</a>
          </small>
        </div>
      </div>
    );
  }
}

export default MenuContainer;
