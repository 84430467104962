import { isCordovaBrowser } from "./cordova";
import { hostLocation } from "./location";

export const setupSentry = () => {
  if (sentryAvailable()) {
    if (!isCordovaBrowser()) {
      return;
    } else {
      var Sentry = require("@sentry/browser");
      Sentry.init({
        dsn: "https://599537a447ba4f37afd7327242552d73@sentry.io/1289931"
      });
    }
  }
};

export const sentryAvailable = (): boolean => {
  return hostLocation() === "localhost" ? false : true;
};
