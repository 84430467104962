import * as React from 'react';
import ChatClient from '@rradar/chat-client';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ChatFormContainer from '../components/ChatFormContainer';
import RecorderService from '../helpers/VoiceRecognition/voiceRecognitionIOS';
import { draw } from '../helpers/VoiceRecognition/Canvas';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  ViewContainerProps,
  ViewContainerState,
  Window,
  rradar__chat_client,
} from './viewcontainerprops';
import { isCordovaBrowser } from '../helpers/cordova';
import Version from '../helpers/version';
import Warning from '../components/Warning';

declare var window: Window;
declare var rradar__chat_client: rradar__chat_client;

class ViewContainer extends React.Component<
  ViewContainerProps,
  ViewContainerState
> {
  chatClient: any;
  chatClientDOMElement: any;
  canvasRef: any;
  voiceRecognition: any;
  constructor(props: ViewContainerProps) {
    super(props);
    this.chatClient = null;
    this.canvasRef = React.createRef<HTMLCanvasElement>();
    this.chatClientDOMElement = null;
    this.voiceRecognition = RecorderService;
  }

  public state: ViewContainerState = {
    userInput: '',
    voiceOverlay: false,
    userInputDisplay: window.cordova.platformId === 'browser',
    errorDisplay: false,
    transcribingVoice: false,
    warning: '',
  };

  async componentDidMount() {
    if (this.chatClient === null) {
      this.chatClient = this.renderChatClient();
      this.setupVoiceRecognition();
    }
    const { version } = await Version.get(this.props.session_token);
    const isLessThen = Version.lessThanMajor(
      APPLICATION_EXPECTED_API_VERSION,
      version
    );
    if (isLessThen && window.cordova.platformId !== 'browser') {
      this.handleErrorDisplay(
        true,
        'A newer version of rradargrace is available. To take advantage of new features, please upgrade Grace from the app store.'
      );
    }
  }

  componentWillUnmount() {
    this.chatClient.removeApplication();
  }

  public setupVoiceRecognition() {
    console.log('Setup Voice Recognition: ');
    console.log('VR session_token: ', this.props.session_token);
    if (this.voiceRecognition.session_token === undefined) {
      this.voiceRecognition.init(
        '../helpers/Audio/Encoders',
        this.handleErrorDisplay,
        {
          micGain: 1,
          createAnalyserNode: true,
          manualEncoderId: 'wav',
        },
        this.props.session_token,
        this.handleTranscribingVoice,
        this.setToChoosingQuestion,
        this.setConversationStatus
      );
    }
  }

  public submitToChatClient = (event?: any) => {
    if (event) {
      event.preventDefault();
    }
    if (this.state.userInput !== '') {
      this.chatClient.send(this.state.userInput);
    }
    this.setState({
      userInput: '',
    });
  };

  public handleButtonClick = () => {
    if (this.state.userInputDisplay === false) {
      this.toggleVoiceRecognition();
    } else {
      this.submitToChatClient();
    }
  };

  public handleTouchEvent = () => {
    this.toggleVoiceRecognition();
  };

  public clearUserInput = () => {
    this.setState({
      userInput: '',
    });
  };

  public setToChoosingQuestion = () => {
    this.chatClient.setToChoosingQuestion();
  };

  public setConversationStatus = (status: string) => {
    this.chatClient.setConversationStatus(status);
  };

  public handleInputChange = (value: string) => {
    const updatedValue = value;
    this.setState({
      userInput: updatedValue,
    });
  };

  public handleInputMode = () => {
    this.setState((prevState) => ({
      userInputDisplay: !prevState.userInputDisplay,
    }));
  };

  public handleErrorDisplay = (
    displayError: boolean = true,
    message: string
  ) => {
    this.setState({
      errorDisplay: displayError,
      warning: message,
    });
  };

  public handleTranscribingVoice = () => {
    this.setState((prevState) => {
      return {
        transcribingVoice: !prevState.transcribingVoice,
      };
    });
  };

  public toggleVoiceRecognition = () => {
    window.chatclient.messages.voiceSound.stop();
    this.setState(
      (prevState) => {
        return {
          voiceOverlay: !prevState.voiceOverlay,
        };
      },
      () => {
        if (this.state.voiceOverlay) {
          this.setState({
            errorDisplay: false,
          });
          if (isCordovaBrowser()) {
            this.voiceRecognition.startRecording().then(() => {
              const analyser: AnalyserNode = this.voiceRecognition.analyserNode;
              console.log('Promised Analyser Node: ', analyser);
              const ctx: AudioContext = this.voiceRecognition.audioCtx;
              analyser.fftSize = 2048;
              analyser.minDecibels = -140;
              analyser.maxDecibels = 0;
              const bufferArray = new Uint8Array(analyser.frequencyBinCount);
              draw(
                this.canvasRef!.current,
                this.canvasRef!.current.getContext('2d'),
                analyser,
                bufferArray,
                analyser.frequencyBinCount
              );
            });
          } else {
            this.voiceRecognition.startRecording();
          }
        } else {
          this.voiceRecognition.stopRecording();
        }
      }
    );
  };

  public requestAuthorizationCode = () => {
    window.location.hash = '#/auth';
  };

  renderChatClient(): any {
    console.log('Render Chat Client: ');
    console.log('chatclient session_token: ', this.props.session_token);
    console.log('chatclient auth_token: ', this.props.auth_token);

    this.chatClientDOMElement = document.getElementById('grace-container');

    return new ChatClient(
      this.chatClientDOMElement,
      {
        initialChatMode: 'Grace',
        inputField: false,
        fontAwesome: true,
        authIncluded: false,
      },
      undefined,
      REACT_APP_API_URL,
      REDIRECT_URL_UID,
      [],
      this.props.voiceOutput,
      this.props.popOutput,
      this.props.auth_token,
      this.props.session_token,
      '',
      this.requestAuthorizationCode,
      this.props.onConnectionErrorCallback,
      this.props.onConnectionReturned,
      true
    );
  }

  render() {
    return (
      <div className='grace-view-container'>
        <Header
          handleClick={this.props.toggleMenu}
          menuPosition={this.props.menuPosition}
        />
        {this.state.warning !== '' ? (
          <div className='grace-warning'>{this.state.warning}</div>
        ) : null}
        <div className='grace-chat-container' id='grace-container' />
        <ChatFormContainer
          forwardRef={this.canvasRef}
          userInputDisplay={this.state.userInputDisplay}
          submitToChatClient={this.submitToChatClient}
          handleInputChange={this.handleInputChange}
          userInput={this.state.userInput}
          voiceOverlay={this.state.voiceOverlay}
        />

        <div
          className={classNames({
            'voiceRecognition-spinner': true,
            'voiceRecognition-spinner-on': this.state.transcribingVoice,
          })}
        />

        <Warning
          display={this.state.errorDisplay}
          message={this.state.warning}
          setDisplay={this.handleErrorDisplay}
        />

        {/* <div
          className={classNames({
            'speech-error-container': true,
            'speech-error-container-active': this.state.errorDisplay,
          })}
          onClick={() => {
            this.setState({
              errorDisplay: false,
            });
          }}
        >
          <div>
            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> Sorry, your
            question couldn't be recognised, please try again.
          </div>
        </div> */}

        <Footer
          handleInputMode={this.handleInputMode}
          handleButtonClick={this.handleButtonClick}
          voiceOverlay={this.state.voiceOverlay}
          userInputDisplay={this.state.userInputDisplay}
        />
      </div>
    );
  }
}

export default ViewContainer;
