import * as React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface IWarningProps {
  setDisplay: (value: boolean, message: string) => void;
  display: boolean;
  message: string;
}

const Warning: React.FunctionComponent<IWarningProps> = (props) => {
  return (
    <div
      className={classnames({
        'speech-error-container': true,
        'speech-error-container-active': props.display,
      })}
      onClick={() => {
        props.setDisplay(false, null);
      }}
    >
      <div>
        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {props.message}
      </div>
    </div>
  );
};

export default Warning;
