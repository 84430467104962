import * as React from 'react';
import Header from '../../components/Header';
import { SettingsScreenProps } from './settingsprops';
import Switch from '../../components/Switch';

class SettingsScreen extends React.Component<SettingsScreenProps, null> {
  constructor(props: SettingsScreenProps) {
    super(props);
  }
  render() {
    return (
      <div className="grace-view-container">
        <Header handleClick={this.props.toggleMenu} menuPosition={this.props.menuPosition} />
        <div className="grace-padding grace-overflow">
          <h2>Settings</h2>
          <hr />
          <h4>Voice Output</h4>
          <p>Toggle audio voice output when receiving a response from rradargrace.</p>
          <Switch
            id="voiceToggle"
            name="voiceToggle"
            title="Toggle Voice Audio Output"
            onChange={this.props.toggleVoice}
            checked={this.props.voiceOutput}
            label="Toggle Voice Audio Output"
            mode={'switch'}
          />
          <hr />
          <h4>Message Received Notification</h4>
          <p>Toggle audio notification when sending and receiving messages.</p>
          <Switch
            id="notificationToggle"
            name="voiceToggle"
            title="Toggle Notification Audio Output"
            onChange={this.props.togglePop}
            checked={this.props.popOutput}
            label="Toggle Notification Sounds"
            mode={'switch'}
          />
          <hr />
          <h4>Application Defaults</h4>
          <p>
            Return application settings to their default, this will log you out of the application and clear any in-app
            settings.
          </p>
          <button className="rcm-red-button" onClick={this.props.clearSettings}>
            Reset Settings
          </button>
        </div>
      </div>
    );
  }
}

export default SettingsScreen;
