import * as React from "react";

interface HeaderProps {
  handleClick: (e: any) => void;
  menuPosition: number;
}

class Header extends React.Component<HeaderProps> {
  render() {
    return (
      <div className="grace-header-container grace-padding">
        <div className="grace-header-logo">
          <img src="assets/img/grace-logo-white.svg" />
        </div>
        <button
          className={
            "hamburger hamburger--arrow" +
            (this.props.menuPosition === 0 ? " is-active" : "")
          }
          onClick={(e) => this.props.handleClick(e)}
          type="button"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </div>
    );
  }
}

export default Header;
