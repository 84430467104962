import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface MicrophoneButtonProps {
  userInputDisplay: boolean;
  voiceOverlay: boolean;
  handleButtonClick: (e: any) => void;
}

const isIOS = (): boolean => {
  let isIOS = false;
  switch (window.navigator.platform) {
    case 'iPhone':
    case 'iPad':
    case 'iPod':
      isIOS = true;
      break;
    default:
      isIOS = false;
      break;
  }
  return isIOS;
};

const MicrophoneButton: React.SFC<MicrophoneButtonProps> = (
  props: MicrophoneButtonProps
) => {
  return (
    <button
      className={
        'grace-footer-microphone' +
        (props.voiceOverlay ? ' microphone-active' : '')
      }
      onMouseDown={(e) => props.handleButtonClick(e)}
    >
      {props.userInputDisplay === false ? (
        props.voiceOverlay ? (
          <FontAwesomeIcon icon={['fas', 'square']} />
        ) : (
          <FontAwesomeIcon icon={['fas', 'microphone']} />
        )
      ) : (
        <FontAwesomeIcon icon={['fas', 'paper-plane']} />
      )}
    </button>
  );
};

export default MicrophoneButton;
