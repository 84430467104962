import * as React from 'react';
import ViewContainer from '../../containers/ViewContainer';
import { AppScreenProps, AppScreenState } from './appprops';

class AppScreen extends React.Component<AppScreenProps, AppScreenState> {
  render() {
    return (
      <ViewContainer
        toggleMenu={this.props.toggleMenu}
        voiceOutput={this.props.voiceOutput}
        popOutput={this.props.popOutput}
        menuPosition={this.props.menuPosition}
        getSessionToken={this.props.getSessionToken}
        defaultToInputDisplay={this.props.defaultToInputDisplay}
        auth_token={this.props.auth_token}
        session_token={this.props.session_token}
        userDetails={this.props.userDetails}
        onConnectionErrorCallback={this.props.onConnectionErrorCallback}
        onConnectionReturned={this.props.onConnectionReturned}        
      />
    );
  }
}

export default AppScreen;
