// import VoiceRecognition from "../voiceRecognition";

// export const setupCanvas = (
//   ref: VoiceRecognition,
//   canvas: HTMLCanvasElement
// ) => {
//   if (ref.canvas === null || ref.canvas === undefined) {
//     ref.canvas = canvas;
//     ref.canvas.height = window.innerHeight;
//     ref.canvas.width = window.innerWidth;
//     ref.canvasContext = ref.canvas.getContext("2d");
//   }
// };

export const draw = (
  canvas: HTMLCanvasElement,
  canvasContext: CanvasRenderingContext2D,
  analyser: AnalyserNode,
  dataArray: any,
  bufferLength: number
) => {
  let WIDTH = canvas.width;
  let HEIGHT = canvas.height;

  requestAnimationFrame(() =>
    draw(canvas, canvasContext, analyser, dataArray, bufferLength)
  );
  canvasContext.clearRect(0, 0, WIDTH, HEIGHT);
  // console.log('Data Point', dataArray);
  // analyser.getByteFrequencyData(dataArray);
  analyser.getByteTimeDomainData(dataArray);
  canvasContext.fillRect(0, 0, WIDTH, HEIGHT);

  canvasContext.fillStyle = "rgba(27, 27, 27, .8)";
  canvasContext.lineWidth = 4;
  canvasContext.strokeStyle = "rgba(255, 255, 255, 0.3)";

  canvasContext.beginPath();

  var sliceWidth = (WIDTH * 1.0) / bufferLength;
  var x = 0;

  for (var i = 0; i < bufferLength; i++) {
    var v = dataArray[i] / 128.0;
    var y = (v * HEIGHT) / 2;

    if (i === 0) {
      canvasContext.moveTo(x, y);
    } else {
      canvasContext.lineTo(x, y);
    }

    x += sliceWidth;
  }

  canvasContext.lineTo(canvas.width, canvas.height / 2);
  canvasContext.stroke();
};
