import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Application from './ts/Application';
import { setupSentry } from './ts/helpers/sentry';
import { postAuthorizationTokens } from './ts/helpers/postMessage';
import {
  handleStorage,
  Methods,
  checkStorageTypeAvailable,
} from './ts/helpers/storage';
const { detect } = require('detect-browser');
const browser = detect();
import './scss/index.scss';
import '../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';
import './polyfill';
import './icons';

export interface Window {
  cordova: any;
  NativeStorage: any;
  plugins: any;
  navigator:
    | any
    | {
        splashscreen: { hide: () => void };
      };
  Howler: any;
  opener: any;
  AudioContext: AudioContext;
}
declare var window: Window;

const getPermissions = (): any => {
  window.plugins.speechRecognition.isRecognitionAvailable(
    () => {
      console.log('Is Speech Recognition Available? ');
      window.plugins.speechRecognition.hasPermission((result: any) => {
        if (result === false) {
          window.plugins.speechRecognition.requestPermission();
        }
      });
    },
    (error: any) => {
      console.log(`Error Handling Speech Recognition ${error}`);
    }
  );
};

const setupFromStorage = async () => {
  console.log('Setting Up From Storage');
  let value = null;
  const response = checkStorageTypeAvailable();
  const hasViewedTutorial = await handleStorage(
    response,
    Methods.GET,
    'hasViewedTutorial'
  );

  const userDetails = await handleStorage(response, Methods.GET, 'userDetails');

  const session_token = await handleStorage(
    response,
    Methods.GET,
    'session_token'
  );
  const notificationPop = await handleStorage(
    response,
    Methods.GET,
    'notificationPop'
  );
  const voiceOutput = await handleStorage(response, Methods.GET, 'voiceOutput');
  console.log('voiceOuput: ', voiceOutput);

  switch (browser && browser.name) {
    case 'edge':
      if (window.opener !== undefined) {
        console.log('Edge Browser and window.opener does not equate to null');
        postAuthorizationTokens('', String(session_token));
      }
      break;

    default:
      if (window.opener !== null) {
        console.log('Edge Browser and window.opener does not equate to null');
        postAuthorizationTokens('', String(session_token));
      }
  }

  value = {
    hasViewedTutorial: hasViewedTutorial === true ? true : false,
    userDetails: userDetails,
    session_token: session_token,
    notificationPop: notificationPop,
    voiceOutput: voiceOutput,
    defaultToInputDisplay: false,
  };

  console.log('Response From handleStorage: ', value);
  return value;
};

const startApp = async () => {
  console.log('Starting Up Application ...');
  console.log('Awaiting Setup...');
  await setupSentry();
  console.log('Sentry Setup');
  let props = await setupFromStorage();
  console.log('Setup From Storage', props);
  await getPermissions();
  console.log('Getting Permissions');
  window.navigator.splashscreen.hide();
  setTimeout(function () {
    return ReactDOM.render(
      <Application {...props} />,
      document.getElementById('app')
    );
  }, 500);
};

if (window.cordova) {
  document.addEventListener('deviceready', startApp, false);
  document.addEventListener('pause', () => {
    if (window.Howler) {
      window.Howler.unload();
    }
  });
} else {
  startApp();
}
