import * as React from 'react';
import AudioOverlay from './AudioOverlay';
import Form from './Form';

export interface ChatFormContainerProps {
  forwardRef: React.Ref<HTMLCanvasElement>;
  submitToChatClient: (e: any) => void;
  handleInputChange: (e: any) => void;
  userInput: string;
  userInputDisplay: boolean;
  voiceOverlay: boolean;
}

const ChatFormContainer: React.SFC<ChatFormContainerProps> = (
  props: ChatFormContainerProps
) => (
  <React.Fragment>
    <AudioOverlay
      forwardRef={props.forwardRef}
      voiceOverlay={props.voiceOverlay}
      userInput={props.userInput}
    />
    <Form
      userInputDisplay={props.userInputDisplay}
      submitToChatClient={props.submitToChatClient}
      userInput={props.userInput}
      handleInputChange={props.handleInputChange}
    />
  </React.Fragment>
);

export default ChatFormContainer;
