import * as React from 'react';
import { AuthScreenProps } from './authimports';
import { getAuthenticationURL } from '../../helpers/authorization';
import { useParams } from 'react-router-dom';

export interface Window {
  cordova: any;
  setInterval: (cb: () => {}, time: number) => {};
  open: any;
  location: any;
}
declare var window: Window;

const AuthScreen = (props: AuthScreenProps) => {
  let { companyselect } = useParams<{ companyselect?: string }>();
  React.useEffect(() => {
    const getAuthenticationURLResponse = getAuthenticationURL();
    props.onConnectionReturned();
    getAuthenticationURLResponse
      .then((res) => {
        let url;
        url = res.url;
        if (companyselect) {
          let split = (res.url as string).split('?');
          const routePath = split[0] + '/companyselect';
          url = routePath + '?' + split[1];
          console.log(' URL: ', url);
        }
        openLogin(url);
      })
      .catch((err) => {
        console.log('Error: ', err);
        props.onConnectionErrorCallback();
      });
    // cdm
  }, []);

  const openLogin = (url: any) => {
    console.log('####### Auth.tsx ######');

    const windowParameters_1 =
      'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,';
    const windowParameters_2 =
      'width=300,innerWidth=490,height=300,innerHeight=623,left=-500,top=-500';

    var authWindow: undefined | any = undefined;

    if (
      window.cordova.InAppBrowser &&
      window.cordova.platformId !== 'browser'
    ) {
      window.open = window.cordova.InAppBrowser.open;
      authWindow = window.open(
        `${url}&theme=dark&state=mobile&redirect_url_uid=${REDIRECT_URL_UID}`,
        '_blank',
        windowParameters_1 + windowParameters_2
      );
    } else {
      console.log('\n\n\nOpen Login - Browser\n\n\n');
      window.location = `${url}&theme=dark&redirect_url_uid=${REDIRECT_URL_UID}`;
    }

    authWindow.addEventListener('loadstart', (event: any) => {
      let parser = document.createElement('a');
      parser.href = event.url;

      let res = parser.href;
      let successRegex = /auth_token=([A-Za-z0-9_]*)/;
      let errorRegex = /error=([A-Za-z0-9_]*)/;

      if (successRegex.test(res)) {
        let auth_token = res.match(successRegex)[1];
        authWindow.close();
        props.setAuthTokenInState(auth_token);
      } else if (errorRegex.test(res)) {
        authWindow.close();
      }
    });

    var leftDomain = false;
    var interval = setInterval(function () {
      function queryString(localWindow: any, prop: any) {
        var params: any = {};
        var search = decodeURIComponent(
          localWindow.location.href.slice(
            localWindow.location.href.indexOf('?') + 1
          )
        );
        var definitions = search.split('&');

        definitions.forEach(function (val, key) {
          console.log('Vals: ', val);
          var parts: any = val.split('=', 2);
          params[parts[0]] = parts[1];
        });
        localWindow.close();
        return prop && prop in params ? params[prop] : params;
      }
      try {
        if (authWindow.document.domain === document.domain) {
          const authToken = queryString(authWindow, 'auth_token');
          console.log('Param: ', authToken);
          clearInterval(interval);
          authWindow.postMessage({ message: 'requestResult' }, '*');
        } else {
          leftDomain = true;
        }
      } catch (e) {
        if (authWindow.closed) {
          clearInterval(interval);
          return;
        }
        leftDomain = true;
      }
    }, 500);
  };

  return (
    <div className='grace-view-container'>
      <div className='grace-padding'>
        <div className={'spinner'}>
          <div className='dbl-spinner' />
          <div className='dbl-spinner dbl-spinner--2' />
        </div>
      </div>
    </div>
  );
};

export default AuthScreen;
