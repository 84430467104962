import * as React from 'react';
import Header from '../../components/Header';
import { HistoryScreenProps } from './historyprops';

class HistoryScreen extends React.Component<HistoryScreenProps> {
  render() {
    return (
      <div className="grace-view-container">
        <Header
          handleClick={this.props.toggleMenu}
          menuPosition={this.props.menuPosition}
        />
        <div className="grace-padding">
          <h2>Conversation History</h2>
        </div>
      </div>
    );
  }
}
export default HistoryScreen;
