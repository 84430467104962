export const processFormData = (audioBlob: Blob, type: string): FormData => {
  const data: FormData = new FormData();
  data.append("file", audioBlob, `audio.${type}`);
  return data;
};

export const getFileTypeFromMimeType = (type: Blob["type"]): string => {
  const splitType = type.split("/");
  return splitType[1];
};
